<template>
    <a href="#main" tabindex="1" class="skip">Skip to main content</a>
    <mainDiv id="main">
        <spin-modal :title="title" :loading="loading" v-if="showModal" @close="closeModal"></spin-modal>  
        <div class="title1" role="heading">Contact Inn At Windmere, LLC</div>
        <div class="row1">
            <div class="left1">
                <div class="info">
                    <div><span :style="{ 'font-weight': boldText}">Address: </span>2077 County Road 40, Auburn IN, 46706</div>
                    <div class="phone"><span :style="{ 'font-weight': boldText}">Phone: </span>260.927.5116</div>
                </div>
                <div class="text2"><span :style="{ 'font-weight': boldText}">Contact us with any questions: </span>Just fill out the form below and we will get back to you a s soon as possible!</div>
                <div class="space1"></div>
                <div class="text2"><span :style="{ 'font-weight': boldText}">For Labor Day reservations: </span>please contact us directly by phone <a target="_blank" style="white-space: nowrap" href="tel:260-927-5116">260-927-5116</a>.</div>
            </div>
            <div class="right1" v-if="!isMobile">
                <img alt="A mug with the Inn At Windmere logo sitting next to a book called The Little Prince." class="img1" :src="require('../../assets/2023Update/Others/Window Seat-17.jpg')" />
            </div>
        </div>
        <div class="row2">
            <div class="fTitle1" v-if="!isMobile">Inn At Windmere Contact Form</div>
            <div class="space1"></div>            
            <div class="fTitle2">Name<span class="required">*</span></div>            
            <div class="row3">
                <input type="text" aria-label="First Name" placeholder="First" class="textInput" v-model="firstName" />                
                <input type="text" aria-label="Last Name" placeholder="Last" class="textInput" v-model="lastName" />
            </div>
            <div class="row3">
                <div class="formError"><span v-if="validate===true && firstName===''">Please enter your first name</span></div>
                <div class="formError"><span v-if="validate && lastName ===''">Please enter your last name</span></div>
            </div>
            <div class="row3">
                <div class="fTitle2">Email<span class="required">*</span></div> 
                <div :class="['fTitle2', 'fRight']">Phone</div>           
            </div>
            <div class="row3">
                <input type="text" aria-label="Email Address" class="textInput" v-model="email" />
                <input type="text" aria-label="Phone Number" class="textInput" v-model="phone" />
            </div>
            <div class="row3">
                <div class="formError"><span v-if="validate===true && email===''">Please enter your email address</span></div>
                <div class="formError"></div>
            </div>
            <div class="fTitle2">Message<span class="required">*</span></div> 
            <textarea class="taInput" aria-label="Message" v-model="message"></textarea>
            <div class="taError"><span v-if="validate===true && message ===''">Please enter a message</span></div>
            <div class="submitRow">
                <div class="submitBtn" role="button" @click="submit()">Submit</div>
                <div v-if="emailStatus==='success'" :class="['emailStatus','emailSuccess']">Your message has been sent, Thanks!</div>
                <div v-else-if="emailStatus==='failure'" :class="['emailStatus','emailFailure']">Sorry, your message failed to send</div>
                <div v-else class="emailStatus"></div>
            </div>
        </div>
    </mainDiv>
</template>

<script>
import MainDiv from '../MainDiv.vue';
import emailjs from '@emailjs/browser';
import SpinModal from '../SpinModal.vue';
export default {    
  components: {
    'mainDiv': MainDiv,
    SpinModal
  },
  data() {
    return {
        boldText: 400,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        validate: false,       
        message: '', 
        showModal: false,
        loading: true,
        emailStatus:'',
        title: 'Sending Message...',
        showEmailStatus: true,
        isMobile:false,
    }
  },
  methods: {
    async submit(){
        this.validate=true;
        if(this.firstName != '' && this.lastName != ''
            && this.email != '' && this.message != ''){
            this.loading=true;
            this.title="Sending Message...";
            this.showModal=true;
            try {
                console.log(this.message);
                var templateParams = {
                firstname: this.firstName,
                lastname: this.lastname,
                email: this.email,
                phone: this.phone,
                message: this.message,
                company: 'innatwindmere',
            };
                await emailjs.send('service_brs83or','template_ivv217q',templateParams,'3HUgnihPV6xFRGPvI').then((resp) =>{
                    this.loading=false;
                    this.validate=false;                    
                    if(resp){
                        this.emailStatus='success';
                        this.title="Your message has been sent!";
                        this.firstName='';
                        this.lastName='';
                        this.phone='';
                        this.email='';
                        this.message='';
                    } else {
                        this.emailStatus='failure';
                        this.title="Sorry, your message failed to send";
                    }
                });
                await this.timeout(2000);
            } catch(error){
                console.log(error);
                // this.error=error;
                // this.showFailure=true;                
            }
            this.validate=false;
            this.firstName='';
            this.lastName='';
            this.phone='';
            this.email='';
            this.message='';
            this.showModal=false;
        }
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    closeModal(){
        this.showModal=false;
    },
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'contact' }}));
    window.addEventListener('resize',this.windowSize);            
        this.windowSize();   
  }
}
</script>
<style scoped>
.info{
    /* font-style:italic; */
    margin:25px;
}
.phone {
    margin-top:10px;
}
.row1 {
    display:flex;
    width:100%;
    justify-content: space-between;
}
.fTitle1{
    font-size:35px;
    font-weight:400;
}
.fTitle2{
    font-size:18px;
    font-weight:400;
    display:flex;    
}
.space1{
    height:30px;
}
.required {
    width:fit-content;    
    color:red;
    margin-top: 4px;   
    margin-left:2px;
}
.right1 {
    width:38%;
}
.left1 {
    width:62%;
}
.img1 {
    width:100%;
    height:100%;
    object-fit:cover;
}
.row3 {
    display:flex;
    justify-content: space-between;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.textInput {
    width: 49%;
    height: 40px;
    border:none;    
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.406);
    box-sizing: border-box;
    
}
.textInput::placeholder{
    font-size: 16px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight:300;
    color:rgb(168, 168, 168);
    opacity:.8;
}
.fRight {    
    width:49%;
    margin:0;
}
.formError {
    width:49%;
}
.formError, .taError {    
    margin:0;
    text-align:center;
    font-size:14px;
    color:red;
    height:30px;
}
.taInput {
    width:100%;
    border:none;    
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.406);
    box-sizing: border-box;
    height:80px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.submitBtn {
    padding:10px;
    background-color:#6d2328;
    width:fit-content;
    color:#fff;
    border-radius:4px;
    grid-row-start:1;
    grid-column-start:1;
    z-index:10;
}
.submitBtn:hover {
    cursor:pointer;
    background-color:#754b54
}
.emailFailure {
    color:red;
}
.emailSuccess {
    color:green;
}
.emailStatus {
    grid-row-start:1;
    grid-column-start:1;
    z-index:1;
    width:100%;
    text-align:center;
}
.submitRow {
    display:grid;
}
@media only screen and (max-width: 1152px) {
    .row1{
        display:block;
    }
    .left1 {
        width:100%;
    }
}
</style>